import logger from './../shared/logger.js'

const { debug, warn } = logger('[FormstackFacade]')

/**
 * NOTE: Interactions with Formstack are done through their Form Builder (V4),
 * aka fsApi, which is loaded by embedded Formstack forms.
 * https://help.formstack.com/s/article/Formstack-most-recent-form-builder-V4-Api-Overview
 * https://live-form-api.formstack.com/index.html
 */

const getForms = () => {
  if (isBlank(window.fsApi)) {
    warn('`fsApi` is not defined')
    return []
  }

  return window.fsApi().getForms()
}

const isBlank = (value) => value === undefined || value === null

const withRescue = (listener) => {
  try {
    listener()
  } catch (error) {
    warn('form listener failed', { error })
  }
}

/**
 * Sets a value for a Formstack form field identified by its internal label.
 * Applies to all matching fields across all rendered forms.
 *
 * @param {string} internalLabel - The internal label of the field to update.
 * @param {any | function(): any} value - The value to set, or a function returning the value.
 */
export const maybeSetValue = (internalLabel, value) => {
  getForms().forEach((form) => {
    const field = form.getFieldByInternalLabel(internalLabel)
    if (isBlank(field)) {
      debug('field not found', { internalLabel, formId: form.id })
      return
    }

    if (typeof value === 'function') {
      value = value()
    }

    if (isBlank(value)) {
      debug('value undefined', { internalLabel, value })
      return
    }

    field.setValue({ value })

    debug('field set', { internalLabel, formId: form.id, value })
  })
}

/**
 * Registers an event listener for multiple Formstack fields.
 * Calls the callback with an array of field values and their labels when the event occurs.
 *
 * @param {string[]} internalLabels - The internal labels of the fields to monitor.
 * @param {string} eventType - The event type to listen for (e.g., "change").
 * @param {function({ fields: Array<{ label: string, value: any }>, formId: string }): void} callback - Function to execute when the event occurs.
 */
export const registerMultiFieldListener = (
  internalLabels,
  eventType,
  callback
) => {
  getForms().forEach((form) => {
    let fields = internalLabels.flatMap((label) => {
      const field = form.getFieldByInternalLabel(label)
      return field ? [{ field, label }] : []
    })

    if (fields.length) {
      form.registerFormEventListener({
        type: eventType,
        onFormEvent: (event) => {
          withRescue(() => {
            fields = fields.map(({ field, label }) => ({
              label,
              value: field.getValue().value,
            }))
            callback({ fields, formId: form.id })
          })

          return Promise.resolve(event)
        },
      })
    }
  })
}
