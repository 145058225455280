import airbrake from '../shared/airbrake'

import { captureFirstVisit, captureLastVisit, migrateKeys, } from './capture-visit'
import { getAnonymousId } from './customerio-facade'
import { getParam, forwardParams } from './search-params'
import { maybeSetValue, registerMultiFieldListener, } from './formstack-facade'
import { push } from './gtm-facade'

document.addEventListener('DOMContentLoaded', () => {
  const notifier = airbrake({
    projectId: 605320,
    projectKey: '5c0eac79df1c1a8ad86d4df539b9af56',
  })

  const email = getParam('email')
  if (email) {
    forwardParams({
      email,
      first: getParam('first_name'),
      last: getParam('last_name'),
    }, '[href^="https://www.ondemandassessment.com/"]')
    /* NOTE: this is used for manual "enhanced conversion" tracking on
     * flatironschool.com. On all other sites (i.e. the Webflow sites), that
     * tracking happens automatically.
     *
     * It's unclear why that difference exists; there are many quirks with
     * flatironschool.com and few people around to explain them. If ever
     * marketing corrects it and is no longer using this custom event as a
     * trigger, then we can likely drop it.
     */
    push({ email, event: 'ec_form_submit' })
  }

  // TODO: remove this migration once we're confident it has been in production
  // long enough (a few weeks?)
  migrateKeys()

  const firstVisitUrl = captureFirstVisit()
  const lastVisitUrl = captureLastVisit()

  maybeSetValue('anonymous_id', getAnonymousId)
  maybeSetValue('first_visit_url', firstVisitUrl)
  maybeSetValue('last_visit_url', lastVisitUrl)

  registerMultiFieldListener(
    ['anonymous_id', 'first_visit_url', 'last_visit_url'],
    'submit',
    ({ fields, formId }) => {
      const unset = fields.filter(({ value }) => !value)
      if (unset.length === 0) return

      const error = new Error('Fields that should have been set were not')
      notifier.notify({
        error,
        context: { formId, unset, severity: 'warning' },
      })
    }
  )
})
