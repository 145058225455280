import logger from './../shared/logger.js'
import { getItem } from './storage.js'

const { debug } = logger('[CustomerIoFacade]')

/**
 * NOTE: Interactions with CustomerIO are done through their "Javascript Source",
 * aka `window.analytics`, which is (currently) loaded separately on the site.
 * https://docs.customer.io/cdp/sources/connections/javascript/js-source/
 *
 * TODO: should we import the source rather than adding it as a separate script
 * tag on the third-party site (i.e. Webflow)?
 * https://docs.customer.io/cdp/sources/connections/javascript/js-source/#import-the-javascript-source
 */

const getUser = () => {
  if (typeof window.analytics?.user === 'function') {
    return window.analytics.user()
  }

  debug('attempting to access user directly')

  return window.analytics?.eventFactory?.user
}

const getAnonymousIdFromLocalStorage = () => {
  debug('attempting to access anonymous ID directly')

  return JSON.parse(getItem('ajs_anonymous_id'))
}

/**
 * Retrieves the anonymous ID of the current user from CustomerIO's `analytics` object.
 *
 * @returns {string|undefined} The anonymous ID of the user, or `undefined` if `analytics` is not available.
 */
export const getAnonymousId = () => {
  const user = getUser()
  if (user) return user.anonymousId()

  return getAnonymousIdFromLocalStorage() || undefined
}
