/**
 * Pushes data into the Google Tag Manager (GTM) dataLayer.
 * Ensures `window.dataLayer` exists before pushing the arguments.
 * https://developers.google.com/tag-platform/tag-manager/datalayer
 *
 * @param {...any} args - The data to push into the dataLayer.
 */
export const push = (...args) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(...args)
}
