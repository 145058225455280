const appendParams = (url, params) => {
  url = new URL(url)

  Object.entries(params).forEach(([key, value]) => {
    if (url.searchParams.get(key)) return

    url.searchParams.append(key, value)
  })

  return url.toString()
}

export const forwardParams = (params, selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    el.href = appendParams(el.href, params)
  })
}

export const getParam = (name, callback) => {
  const params = new URLSearchParams(window.location.search)
  return params?.get(name)
}
