import { Notifier } from '@airbrake/browser'
import logger from './logger.js'

const IGNORED = [
  // This error is triggered by crawling email clients; see
  // https://github.com/DataDog/browser-sdk/issues/2715
  /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
]

const isIgnoredError = (notice) =>
  notice.errors.some(({ message }) =>
    IGNORED.some((pattern) => pattern.test(message))
  )

const isSnippetError = (notice) =>
  notice.errors.some(({ backtrace }) =>
    backtrace.some(({ file }) =>
      file?.startsWith('https://flatiron-school-snippets')
    )
  )

const isStagingError = (notice) =>
  notice.errors.some(({ backtrace }) =>
    backtrace.some(({ file }) =>
      file?.startsWith('https://flatiron-school-snippets-staging')
    )
  )

const isTest = () =>
  typeof process !== 'undefined' &&
  process.env &&
  process.env.NODE_ENV === "test"; // eslint-disable-line

/**
 * Returns an instance of Airbrake's notifier
 *
 * NOTE: we heavily filter notices because we do not control the sites
 * that host our snippets (i.e. they produce a lot of errors that aren't ours)
 *
 */
export default function airbrake ({ projectId, projectKey }) {
  const { debug } = logger('[Airbrake]')

  const notifier = new Notifier({
    projectId,
    projectKey,
    environment: isTest() ? 'test' : 'production',
  })

  notifier.addFilter((notice) => {
    if (!isSnippetError(notice)) {
      debug('notice filtered', notice.errors)
      return null
    }

    if (isIgnoredError(notice)) {
      debug('notice ignored', notice.errors)
      return null
    }

    if (isStagingError(notice)) {
      notice.context.environment = 'staging'
    }

    return notice
  })

  notifier.callAsync = async (fn, ...args) => {
    try {
      return await fn(...args)
    } catch (err) {
      notifier.notify(err)
      throw err
    }
  }

  return notifier
}
