/**
 * Returns a logger with a default prefix and optional details; e.g.
 *
 * const { debug } = logger("[Foo]", { baz: "qux" }
 * debug("Action performed", { action: "did-a-thing" })
 *
 * This is the equivalent of:
 *
 * console.debug("[Foo] Action performed", { baz: "qux", action: "did-a-thing" })
 *
 */
export default function logger (prefix, defaultDetails = {}) {
  const log = (level, msg, additionalDetails = {}) => {
    const details = { ...defaultDetails, ...additionalDetails }
    const message = `${prefix} ${msg}`

    if (Object.keys(details).length === 0) {
      console[level](message)
    } else {
      console[level](message, details)
    }
  }

  return {
    debug: (...args) => log('debug', ...args),
    warn: (...args) => log('warn', ...args),
  }
}
