import logger from './../shared/logger.js'

const { debug, warn } = logger('[Storage]')

const storage = () => {
  try {
    return window.localStorage
  } catch (error) {
    warn('Unable to access localStorage', { error })
  }
}

export const getItem = (key) => {
  const store = storage()

  if (!store) {
    warn(`Unable to get ${key}`, { error: 'localStorage unavailable' })
    return null
  }

  return store.getItem(key)
}

export const setItem = (key, value) => {
  const store = storage()

  if (!store) {
    warn(`Unable to set ${key}`, { error: 'localStorage unavailable' })
    return value
  }

  try {
    store.setItem(key, value)
    debug(`Set ${key}`, { key, value })
  } catch (error) {
    warn(`Failed to set ${key}`, { error, key, value })
  }

  return value
}
