import logger from './../shared/logger.js'
import { getItem, setItem } from './storage.js'

const { debug } = logger('[CaptureVisit]')

const referredFromOrigin = () => {
  let referrer

  try {
    referrer = new URL(document.referrer)
  } catch {
    referrer = {}
  }

  return location.hostname === referrer.hostname
}

/**
 * Captures and stores the URL of a user's first visit to the site.
 * If a first visit URL is already stored, it returns that URL.
 */
export const captureFirstVisit = () => {
  const key = 'captureFirstVisit'
  const value = getItem(key)

  if (referredFromOrigin()) {
    debug('not the first visit', { key, value })
    return value
  }

  if (value) {
    debug('first visit already captured', { key, value })
    return value
  }

  return setItem(key, location.href)
}

/**
 * Captures and stores the URL whenever the referrer is either blank or a
 * different host. In other words, keeps track of the most recent URL the
 * visitor landed on.
 */
export const captureLastVisit = () => {
  const key = 'captureLastVisit'

  if (referredFromOrigin()) {
    debug('not a new visit', { key })
    return getItem(key)
  }

  return setItem(key, location.href)
}

/**
 * Migrates data from an older keys to the latest versions
 * TODO: remove when no longer needed
 */
export const migrateKeys = () => {
  ['captureFirstVisit', 'captureLastVisit'].forEach((newKey) => {
    if (getItem(newKey)) return

    const oldKey = `${newKey}[${location.hostname}]`
    const value = getItem(oldKey)

    if (!value) return

    setItem(newKey, value)
  })
}
